/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React views
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import LogoutIcon from '@mui/icons-material/Logout';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Dashboard from "views/dashboard";
import ReviewList from "views/ReviewList";
import Billing from "views/billing";
import RTL from "views/rtl";
import Notifications from "views/notifications";
import Profile from "views/profile";
import SignIn from "views/authentication/SignIn";
import SignUp from "views/authentication/SignUp";
import HotelDetails from "views/HotelDetails";
// @mui icons
import Icon from "@mui/material/Icon";
import Plans from "views/Plans";
import QrCodeSection from "views/QrCodeSection";
import { Money, QrCode } from "@mui/icons-material";
import SurveyCard from "views/SurveyCard";
import UserList from './views/UsersList'
import ProtectedRoute from 'components/ProtectedRoute';
import HotelsList from 'views/HotelsList';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BuisnessCalls from 'views/BuisnessCalls';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <ProtectedRoute Component={Dashboard} componentPath='/dashboard'/> ,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <ProtectedRoute Component={Profile} componentPath='/profile' />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <ProtectedRoute Component={Notifications} componentPath='/notifications' />,
  // },
  {
    type: "collapse",
    name: "Users's",
    key: "users",
    icon: <SentimentVerySatisfiedIcon />,
    route: "/allUsers",
    component: <ProtectedRoute Component={UserList} componentPath="/allUsers" />,
  },
  {
    type: "collapse",
    name: "Business's",
    key: "business",
    icon: <ApartmentIcon />,
    route: "/allBusinesses",
    component: <ProtectedRoute Component={HotelsList} componentPath="/allBusinesses" />,
  },
  {
    type: "collapse",
    name: "Business Calls",
    key: "businessCalls",
    icon: <ContactPhoneIcon />,
    route: "/buisnessCalls",
    component: <ProtectedRoute Component={BuisnessCalls} componentPath="/buisnessCalls" />,
  },
  // {
  //   type: "collapse",
  //   name: "Review's",
  //   key: "reviews",
  //   icon: <SentimentVerySatisfiedIcon />,
  //   route: "/allReviews",
  //   component: <ProtectedRoute Component={ReviewList} componentPath="/allReviews" />,
  // },
  // {
  //   type: "collapse",
  //   name: "All Hotels",
  //   key: "Hotel Detail",
  //   icon: <CorporateFareIcon />,
  //   route: "/hotelDetails",
  //   component: <ProtectedRoute Component={HotelDetails} componentPath='/hotelDetails' />,
  // },
  {
    type: "collapse",
    name: "Handle Plans",
    key: "plans",
    icon: <Money />,
    route: "/plans",
    component: <ProtectedRoute Component={Plans} componentPath="/plans" />,
  },
  // {
  //   type: "collapse",
  //   name: "View Your Qr",
  //   key: "qrCode",
  //   icon: <QrCode />,
  //   route: "/customizeQr",
  //   component: <ProtectedRoute Component={QrCodeSection} componentPath="/customizeQr" />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <ProtectedRoute Component={Billing} componentPath="/billing" />,
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <LogoutIcon onClick={() => {localStorage.clear()}} />,
    route: "/authentication/sign-in",
    component: <SignIn/>,
  },
  // {
  //   type: "collapse",
  //   name: "View SurveyCard",
  //   key: "surveyCard",
  //   icon: <Money />,
  //   route: "/surveyCard",
  //   component: <SurveyCard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },

];

export default routes;
