// Action types for user creation and login
export const USER_CREATE_PENDING = 'USER_CREATE_PENDING';
export const USER_CREATE_FULFILLED = 'USER_CREATE_FULFILLED';
export const USER_CREATE_REJECTED = 'USER_CREATE_REJECTED';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const SIGNUP_PENDING = 'SIGNUP_PENDING';
export const SIGNUP_FULFILLED = 'SIGNUP_FULFILLED';
export const SIGNUP_REJECTED = 'SIGNUP_REJECTED';

export const GET_REFRESH_TOKEN_PENDING = 'GET_REFRESH_TOKEN_PENDING';
export const GET_REFRESH_TOKEN_FULFILLED = 'GET_REFRESH_TOKEN_FULFILLED';
export const GET_REFRESH_TOKEN_REJECTED = 'GET_REFRESH_TOKEN_REJECTED';


export const VALIDATE_TOKEN_PENDING = 'VALIDATE_TOKEN_PENDING';
export const VALIDATE_TOKEN_FULFILLED = 'VALIDATE_TOKEN_FULFILLED';
export const VALIDATE_TOKEN_REJECTED = 'VALIDATE_TOKEN_REJECTED';

// Action types for admin functionalities
export const GET_ALL_HOTELS_PENDING = 'GET_ALL_HOTELS_PENDING';
export const GET_ALL_HOTELS_FULFILLED = 'GET_ALL_HOTELS_FULFILLED';
export const GET_ALL_HOTELS_REJECTED = 'GET_ALL_HOTELS_REJECTED';

export const GET_ALL_USERS_PENDING = 'GET_ALL_USERS_PENDING';
export const GET_ALL_USERS_FULFILLED = 'GET_ALL_USERS_FULFILLED';
export const GET_ALL_USERS_REJECTED = 'GET_ALL_USERS_REJECTED';

export const DISABLE_USER_PENDING = 'DISABLE_USER_PENDING';
export const DISABLE_USER_FULFILLED = 'DISABLE_USER_FULFILLED';
export const DISABLE_USER_REJECTED = 'DISABLE_USER_REJECTED';

export const ENABLE_USER_PENDING = 'ENABLE_USER_PENDING';
export const ENABLE_USER_FULFILLED = 'ENABLE_USER_FULFILLED';
export const ENABLE_USER_REJECTED = 'ENABLE_USER_REJECTED';

export const GET_ALL_PLANS_PENDING = 'GET_ALL_PLANS_PENDING';
export const GET_ALL_PLANS_FULFILLED = 'GET_ALL_PLANS_FULFILLED';
export const GET_ALL_PLANS_REJECTED = 'GET_ALL_PLANS_REJECTED'; 

export const GET_ALL_REVIEWS_PENDING = 'GET_ALL_REVIEWS_PENDING';
export const GET_ALL_REVIEWS_FULFILLED = 'GET_ALL_REVIEWS_FULFILLED';
export const GET_ALL_REVIEWS_REJECTED = 'GET_ALL_REVIEWS_REJECTED'; 

export const CLEAR_PLANS_PENDING = 'CLEAR_PLANS_PENDING';
export const CLEAR_PLANS_FULFILLED = 'CLEAR_PLANS_FULFILLED';
export const CLEAR_PLANS_REJECTED = 'CLEAR_PLANS_REJECTED';

export const LIST_PLANS_PENDING = 'LIST_PLANS_PENDING';
export const LIST_PLANS_FULFILLED = 'LIST_PLANS_FULFILLED';
export const LIST_PLANS_REJECTED = 'LIST_PLANS_REJECTED';

export const ADD_PLAN_PENDING = 'ADD_PLAN_PENDING';
export const ADD_PLAN_FULFILLED = 'ADD_PLAN_FULFILLED';
export const ADD_PLAN_REJECTED = 'ADD_PLAN_REJECTED';

export const FETCH_PLAN_INFO_PENDING = 'FETCH_PLAN_INFO_PENDING';
export const FETCH_PLAN_INFO_FULFILLED = 'FETCH_PLAN_INFO_FULFILLED';
export const FETCH_PLAN_INFO_REJECTED = 'FETCH_PLAN_INFO_REJECTED';

export const DELETE_PLAN_PENDING = 'DELETE_PLAN_PENDING';
export const DELETE_PLAN_FULFILLED = 'DELETE_PLAN_FULFILLED';
export const DELETE_PLAN_REJECTED = 'DELETE_PLAN_REJECTED';

export const MODIFY_PLAN_PENDING = 'MODIFY_PLAN_PENDING';
export const MODIFY_PLAN_FULFILLED = 'MODIFY_PLAN_FULFILLED';
export const MODIFY_PLAN_REJECTED = 'MODIFY_PLAN_REJECTED';

export const ENABLE_USER_SUBSCRIPTION_PENDING = 'ENABLE_USER_SUBSCRIPTION_PENDING';
export const ENABLE_USER_SUBSCRIPTION_FULFILLED = 'ENABLE_USER_SUBSCRIPTION_FULFILLED';
export const ENABLE_USER_SUBSCRIPTION_REJECTED = 'ENABLE_USER_SUBSCRIPTION_REJECTED';

export const GET_USER_HOTEL_INFO_PENDING = 'GET_USER_HOTEL_INFO_PENDING';
export const GET_USER_HOTEL_INFO_FULFILLED = 'GET_USER_HOTEL_INFO_FULFILLED';
export const GET_USER_HOTEL_INFO_REJECTED = 'GET_USER_HOTEL_INFO_REJECTED';

export const DISABLE_USER_SUBS_PENDING = 'DISABLE_USER_SUBS_PENDING';
export const DISABLE_USER_SUBS_FULFILLED = 'DISABLE_USER_SUBS_FULFILLED';
export const DISABLE_USER_SUBS_REJECTED = 'DISABLE_USER_SUBS_REJECTED';

export const EDIT_PLAN_DETAILS_PENDING = 'EDIT_PLAN_DETAILS_PENDING';
export const EDIT_PLAN_DETAILS_FULFILLED = 'EDIT_PLAN_DETAILS_FULFILLED';
export const EDIT_PLAN_DETAILS_REJECTED = 'EDIT_PLAN_DETAILS_REJECTED';


export const GET_LINE_ONE_DATA_PENDING = "GET_LINE_ONE_DATA_PENDING";
export const GET_LINE_ONE_DATA_FULFILLED = "GET_LINE_ONE_DATA_FULFILLED";
export const GET_LINE_ONE_DATA_REJECTED = "GET_LINE_ONE_DATA_REJECTED";

export const GET_BAR_GRAPH_DATA_PENDING = "GET_BAR_GRAPH_DATA_PENDING";
export const GET_BAR_GRAPH_DATA_FULFILLED = "GET_BAR_GRAPH_DATA_FULFILLED";
export const GET_BAR_GRAPH_DATA_REJECTED = "GET_BAR_GRAPH_DATA_REJECTED";

export const GET_BUISNESS_CALLS_PENDING = "GET_BUISNESS_CALLS_PENDING";
export const GET_BUISNESS_CALLS_FULFILLED = "GET_BUISNESS_CALLS_FULFILLED";
export const GET_BUISNESS_CALLS_REJECTED = "GET_BUISNESS_CALLS_REJECTED";

// Add other action types as needed
