import {

} from '../../../Redux/action';

export const mapDispatchToProps = (dispatch) => ({

});

export const mapStateToProps = (state) => ({
  
});